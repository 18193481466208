import React from 'react'
import { Link } from 'gatsby'
import Grupo5952 from '../../images/Grupo5952.png'
import styled from 'styled-components'
import { theme } from 'styled-tools'

const Items = styled.div`
	margin-bottom: auto;
	margin: 80px 80px 80px 80px;
	text-align: center;
	@media (max-width: 991.98px) {
		margin: 126px 15px;
	}
`

const Title = styled.h1`
	font-size: 44px;
	@media (max-width: 991.98px) {
		display: none;
	}
`

const Img = styled.img`
	margin-left: auto;
	margin-right: auto;
	position: relative;
	@media (max-width: 991.98px) {
		width: 100%;
	}
`

const Btn = styled.button`
	border: none;
	text-decoration: none;
	margin-top: 20px;
	margin-left: auto;
	margin-right: auto;
	font-size: 18px;
	font-weight: 600;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.17;
	letter-spacing: normal;
	text-align: center;
	color: ${theme('colors.white')} !important;
	background-color: ${theme('colors.primary')};
	border-radius: 30px;
	width: 346px;
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	&:hover {
		text-decoration: none;
		color: ${theme('colors.white')};
		background-color: ${theme('colors.activeHoverBlue')};
	}
	@media (max-width: 991.98px) {
		width: 95%;
		height: 50px;
		&:focus {
			background-color: ${theme('colors.activeHoverBlue')};
			border: 1px solid ${theme('colors.primary')};
		}
	}
`

const TextoP = styled.p`
	line-height: 1.5;
	margin-bottom: 15px;
	font-size: 16px;
	width: 60%;
	margin: 0 auto;
	text-align: center;
	color: ${theme('colors.gray66')} !important;
	@media (max-width: 991.98px) {
		width: 100%;
		line-height: 1.3;
		margin-top: 20px;
		margin-bottom: 20px;
	}
`

const NotFoundComponent = () => (
	<Items>
		<div>
			<Img src={Grupo5952} />
		</div>
		<Title id='title-404-page'>Ooops , página não encontrada</Title>
		<div>
			<TextoP>
				Desculpe, a página que você está tentando acessar não foi encontrada, verifique se o endereço que você digitou
				está correto ou utilize o campo de busca para localizar o que você está procurando.
			</TextoP>

			<Btn as={Link} to='/'>
				Voltar para Home
			</Btn>
		</div>
	</Items>
)

export default NotFoundComponent
