import React from 'react'
import Layout from '../components/layout'
import NotFoundComponent from '@monorepo-amais/commons/components/404/404'

const NotFoundPage = () => (
    <Layout>
        <NotFoundComponent />
    </Layout>
)

export default NotFoundPage